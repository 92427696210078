<template>
  <v-app class="auth-layout">
    <v-main>
      <v-row no-gutters class="h-100">
        <!-- Left -->
        <v-col cols="12" md="5" class="left-side pt-15 d-none d-md-block">
          <!-- Logo -->
          <v-img
            :src="require('@/assets/logo/logo-lg.png')"
            width="200"
            max-width="200"
            class="mx-auto mt-15"
            alt="Payfoot logo"
          />
        </v-col>

        <!-- Right -->
        <v-col cols="12" md="7" class="right-side">
          <router-view />
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>

<style lang="scss">
.auth-layout {
  .v-main {
    background: url("~@/assets/images/auth/background.png") no-repeat center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
  }

  .card {
    display: flex;
    flex-direction: column;
    background: #081525;
    width: 100%;
    max-width: 600px;
    min-height: 80vh;
    padding: 50px;
    border-radius: 15px;

    @media screen and (max-width: 599px) {
      padding: 25px 15px;
    }

    .v-text-field {
      .v-input__prepend-inner .v-icon {
        margin-right: 15px;
      }
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    h1 {
      font-size: 32px;
      margin-bottom: 10px;
    }

    .subtitle-1 {
      font-size: 20px !important;
      font-weight: 500;
    }
  }
}
</style>
